import React from 'react';
import logo from './LayeredBlockchainLogo.png';
import HempchainLogo from './LayeredBlockchainLogo.png';
import HempchainName from './LayeredBlockchainName.png';
import ClearSpacer from './ClearSpacer.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">

          <img src={HempchainLogo} className="App-HempchainLogo" alt="logo" />
          <br/>
          <div><img src={HempchainName} className="App-HempchainName" alt="logo" /></div>

          <p className="App-HempchainTagline" >Enterprise blockchain platform<br/>with applications</p>
          <br/>

          <p class='mission'>IaaS | PaaS | SaaS<br/><br/></p>

          <p className="TopMarginSpace">
              <a className="App-link" href="mailto:info@layeredblockchain.com">
                  Info
              </a>
          </p>

           <p className="TopMarginSpace">
              <img src={ClearSpacer} className="App-ClearSpacer"  alt='clear space'/>
          </p>

      </header>



    </div>
  );
}

export default App;
